/* tajawal-arabic-500-normal*/
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('./files/tajawal-arabic-500-normal.woff2') format('woff2'), url('./files/tajawal-all-500-normal.woff') format('woff');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* tajawal-latin-500-normal*/
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('./files/tajawal-latin-500-normal.woff2') format('woff2'), url('./files/tajawal-all-500-normal.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
